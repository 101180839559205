import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

function openModal( target ) {
  $.magnificPopup.open({
    items: {
      src: target,
      type: 'inline',
    },
    callbacks: {
      close: function(){
        const url = new URL( window.location )
        url.hash = ''
        history.pushState( {}, '', url )
      }
    },
    closeBtnInside: true
  });
}

$doc.ready( ()=>{
  const hash = window.location.hash
  if( hash && $( hash ).length ){
    openModal( hash )
  }
  $doc.on('click', '.js-open-modal', e => {openModal( e.target.hash )} );
})
