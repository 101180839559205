import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(document).ready(function(){
  if( ! getCookie( 'mg-event-cookies' ) ){
    const $eventPopup = $('.js-event-modal')
    $eventPopup.addClass('visible');
    $('.js-event-modal .event-modal__close').click( function(){
      $eventPopup.removeClass('visible');
      setCookie('mg-event-cookies', 'true', 1);
    })
  }
})
