import $ from 'jquery';
import magnificPopup from 'magnific-popup'
const $doc = $(document)

function playVideoItem(event) {
  event.preventDefault();
  const videoSrc = $(event.currentTarget).attr('href');

  // console.log(videoSrc);
  
  $.magnificPopup.open({
    items: {
      src: videoSrc,
      type: 'iframe',
    },
    midClick: true,
    removalDelay: 100,
    mainClass: 'popup--animate',
    iframe: {
      markup: '<div class="mfp-video-header">' +
        '<div class="mfp-close"></div>' +
        '</div>' +
        '<div class="mfp-iframe-scaler">' +
        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay" allowfullscreen></iframe>' +
        '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1&mute=1&enablejsapi=1'
        }
      }
    }
  });
}

$(document).ready(function(){
  $doc.on('click', '.js-video-popup', e => {playVideoItem(e)} );
})