import $ from 'jquery'

const banner = $('.js-banner');
const main = $('main');
const header = $('.js-header');
const mainWrap = main.css('paddingTop');
const hamburger = $('.js-hamburger');

function openBar() {
  if (banner.length > 0 && !hamburger.hasClass('is-active')) {
    banner.addClass('is-loaded');
    const mainPadding = parseInt(mainWrap) + banner.outerHeight();
    header.css('paddingTop', `${banner.outerHeight()}px`);
    main.css('paddingTop', `${mainPadding}px`);
    banner.css('top', 0);
  }
}

function closeBar(event) {
  event.preventDefault()
  banner.removeClass('is-loaded');
  header.css('paddingTop', 0);
  main.css('paddingTop', `${mainWrap}`);
  banner.css('top', `-${banner.outerHeight()}px`);
}

$(document).ready( openBar )
$(window).on( 'resize', openBar )
$(document).on('click', '.announcement-banner__close', closeBar )
$(document).on('click', '.js-hamburger', closeBar )
//$(document).on('click', '.js-hamburger.is-active', openBar )
