import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 13.356H21.8481" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/><path d="M13.2002 2L22.0001 13.3205" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/><path d="M13.2002 24.7298L22.0001 13.3916" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 13.3739L2.1519 13.3739" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/><path d="M10.7998 24.7299L1.99988 13.4094" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/><path d="M10.7998 2.00003L1.99988 13.3383" stroke="#3068FF" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/></svg></button>',
    });
  }

  $(window).on('load resize', (event)=>{
    const dotsWidth = $('.testimonials .slick-dots').innerWidth()
    const next = 'calc(50% + ' + ((dotsWidth/2) + 20) + 'px)'
    const prev = 'calc(50% + ' + ((dotsWidth/2) + 20) + 'px)'
    $('.testimonials .slick-next').css('left', next)
    $('.testimonials .slick-prev').css('right', prev)
  })
}
